/******************************************************************
Site Name: 
Author: 

Stylesheet: Desktop Stylsheet

This is the desktop size. It's larger than an iPad so it will only
be seen on the Desktop. 

******************************************************************/


.header {	

	.clearHeader {

        > .row {height:18vh; @include transition(all 0.3s ease-in-out);}

        #logo {
            width: 208px; height: 100px;
		    background-size: 208px 100px;
            @include transition(all 0.3s ease-in-out);
        }
		
	}
	 
	.darkHeader {

        > .row {height:13vh; @include transition(all 0.3s ease-in-out);}

        #logo {
            width:188px; height:90px;
		    background-size: 188px 90px; 
            @include transition(all 0.3s ease-in-out);
        }

	}
	
	
}

// HOME TEMPLATE 2
body.Template-2 {
}

// HOME TEMPLATE 3
body.Template-3 {
}

// HOME TEMPLATE 4



#content {height:auto;}
