/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet Landscape

Needed to change the menu styling from mobile to desktop

******************************************************************/
.mobileOnly {display:none!important;}
.desktopOnly {display:inline-block!important;}


/*sizing*/
#hero h1, #hero.h1 { font-size: 2.4em; }
h1, .h1, h2, .h2 { font-size: 2em; }
.bigger, h3, .h3, [data-control] {font-size:1.2em;}
#message {font-size:1em;}

/*************************
Colours
*************************/

.white { color: $white; }


/*********************
LAYOUT & GRID STYLES
*********************/
.row, body.home #content #leftcol > div > div, body.fullWidth #leftcol > div > div{
    padding: 2em 0;
}

/* Reduce padding on some rows*/
#breadcrumbs .row {padding: 1em 0;}

/*Increase padding content some rows */

/* Restrict width on some rows and content areas that behave like rows*/

		
/*********************
SPRITE
*********************/
		

    #logo {
        width:146px; height: 70px;
	    background-size: 146px 70px;
	}

/*********************
HEADER STYLES
*********************/


.header {
     	
        #mainMenu {
            display: inline-block;
        }
}




/**************************
HERO - Home
***************************/

body.Home #hero {
    
    height: 78vh;
    .caption { 
        @include center(false, true);
        left:2rem;
        width: 50%;
    }
}


/*********************
NAVIGATION STYLES
*********************/

/*#touchMenu*/
.menu-btn {display:none;}

    .darkHeader .topMenu .nav > li > a:after {background-color: $color-primary; @include transition(all 0.3s ease-in-out 0s);}


.topMenu {
    margin-left: auto;
    margin-right: auto;
    float: left;
    text-align: left;
    clear: none;


    a.toggleMenu {
        display: none;
    }

    .nav {
        position: relative;
        width: auto;
        border: 0;
        display:inline;
        margin: 0 auto;


        > li {
            display: inline-block;
            margin: 0 .5em;

            > a {
                    position: relative;
                    background: transparent;
                    padding: 0.5em 0em;

                    &:hover, &:focus {
                        background: none;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        bottom: 0;
                        left: 0;
                        background-color: $black;
                        visibility: hidden;
                        -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                        @include transition(all 0.3s ease-in-out 0s);
                    }

                    &:hover:after, &.activerootmenulink:after {
                        visibility: visible;
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                    }
                }
            /*Prominent contact link*/
            &:first-child {
                display: none;
            }

            
        }

        li {
            position: relative;

            a {
                padding: 0.5em 0em;
                background: none;
                border-bottom: none;


                &.parent:before {
                    display: none;
                }
            }
            /* highlight current page */
            a.activerootmenulink {
                color: $color-primary;
            }

            &:first-child {
            }

            &:last-child {
            }
            /*
			plan your menus and drop-downs wisely.
			*/
            ul,
            ul.sub-menu,
            ul.children {
                position: absolute;
                z-index: 9999;
                left: -9999px;
                border-top: 1px solid $color-primary;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                li {
                    margin: 0 0;

                    a {
                        padding: 0.5em 0.5em;
                        display: block;
                        width: 250px;
                        border-top: none;
                        border-radius: 0;
                        margin-right: 0;
                        background-color:$white;

                        &:hover,
                        &:focus {
                            border-top: none;
                            background-color:$color-quintus;
                        }

                        &:link {
                        }
                    }

                    &:first-child {
                    }

                    &:last-child {

                        a {
                        }
                    }
                    /*
					if you need to go deeper, go nuts
					just remember deeper menus suck
					for usability.
					*/
                    ul {
                        border-top: none;
                    }
                }
            }
            /* showing sub-menus */
            &:hover ul {
            }
        }
        /* end .menu ul li */
    }
    /* end .nav */
} /* end .topMenu */

.nav > li.hover > ul { left: 0;}
.nav li li ul { left: -9999px; z-index: 99; position:absolute; }
.nav li li.hover ul { left: 100%; top: 0; z-index: 99; position:absolute; }

/* active state on home - not sure where else to put this for now*/






/*********************
CONTENT STYLES
*********************/

/* specific styling to hold background illustrations */

body.Home #content #leftcol > div:first-of-type,
body.Home #content #leftcol > div#div433,
body.Home #content #leftcol > div#div431,
body.Home #content #leftcol > div#div434,
body.fullWidth #content #leftcol > div:first-of-type,
body.fullWidth #content #leftcol > div:last-of-type{

    &:after {
        width:30%;
    }

}

/*Palm*/
body.Home #content #leftcol > div:first-of-type,
body.apartments #content #leftcol > div:first-of-type,
body.facilities #content #leftcol > div:first-of-type,{
    &:after {
		right:5%;
        top:-15%;
    }

}

/*flower*/
body.Home #content #leftcol > div#div434,
body.gallery #content #leftcol > div:first-of-type,
body.contact #content #leftcol > div:first-of-type{
    &:after {
		right:5%;
        top:-15%;
    }

}

/*Ball bottom*/
body.Home #content #leftcol > div#div433,
body.apartments #content #leftcol > div:nth-child(5),
body.fullWidth #content #leftcol > div:last-of-type{
    &:after {
		left:-10%;
        bottom:-30%;
    }
}

/*Ball top*/
body.location  #content #leftcol > div:first-of-type,
body.specials #content #leftcol > div:first-of-type{
    &:after {
		right:5%;
        top:-15%;
    }

}

/*Usage - format content in columns using simple columncount */
.col-2 {column-count:2;}
/*Usage - format content in columns using grid */
.col-3, .col-4, .col-6 {
    > div, > li {
        grid-column: span 1;
    }
}
.col-6 {
    grid-gap: 1.75em;

}

// usage - specials

.article-stack {
  display: grid;
  position: relative; // imperative for the overlapping to work
}


.article-stack {
    grid-template-columns: repeat(12, 1fr);

    .article-stack__item img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit:cover;
    }

    .article-stack__item--bottom {
        grid-column: 1 / span 9;
        grid-row: 1;

    }

    .article-stack__item--top {
        grid-row: 1;
        grid-column: 8 / span 5;
        margin-top: 10%; margin-bottom: 10%; // slightly arbitrary, keeps proportion once resized
        z-index: 1; // tells the browser to make this image on top
    }
}

/*********************
BLOCK LAYOUT
**********************/	



.columnlayout {
       
	gap: 2em;

    > li, > div > article { width: 20%;} 


	&.feature-text-left {
        display: flex;
        flex-wrap: nowrap;
		align-items:center;
		> div {
			width:37.5%;
		}
		> div:last-of-type {
			width:25%;
		}
		.text-wrap {
			padding: 2em 0em 2em 2em;
		}
	}

	
	

	
}



/*********************
FOOTER STYLES
*********************/

    
    /*Bottom Blocks*/

    #bottomBlocks .block {
        margin-bottom: 2em;
        margin: 0 auto;
    }

    /*layout needed for list items */
    #bottomBlocks #block_26 {

        ul {
           
            li {
                display:inline-block;
                width: 48%;
                margin-bottom: 0.5em;

            }
        }

    }

    #footer {
            .last {text-align:right;}
        }

/* ================================================ */
/* SLIDESHOWS */
/* ================================================ */  

/*Full width slider */
body.Home #hero {
	overflow:hidden;
	.slick-slider { }
}

/*Full width slider */

.slick-prev, .slick-next {
    display:block;
    visibility:visible;
    top: 48%; width:50px; height:50px; background-color:red;outline:none;border:none;

    background:transparent;
    position:absolute;
    top:0;
    width:50%;
    height:70%;
    cursor: url(../images/arrow-left.png),default;
        
}
.slick-next {
    right: 0em;
    cursor: url(../images/arrow-right.png),default;
}

ul.slick-dots {
        
        li {
            
            button {
                
                &:before {
                    color:$white;
                }

            }
        }
        li.slick-active {
            button {
                &:before {
                    color:$white;
                }

            }
        }
    }

ul.responsive {
	
	.slick-list {
		.slick-track {
			.slick-slide {
				
				img { margin-bottom:6%;}

                .slideshow_caption {
                    /*text-align: center;*/
				    width: 70%;
                    bottom:0;
                    right:2em;
                    position: absolute;  
                }

                    

                .slideshow_caption.active {
                    @include border-radius($small-border-radius);
                    padding: 2rem;
                }
			
			}
		}
	}

    .slick-dots {bottom:15%; }
}





/*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        height: 50px;
        width: 50px;
    }

// BOOK FORM Slide-out
#bookForm {
    #bookForm-inner {
        width: 30vw;
    }
}